<template>
  <div class="container-fluid">
    <div class="row clearfix">
      <!-- カテゴリーリスト -->
      <div class="column full">
        <div class="spacer height-20"></div>
        <div class="iv2-blog-archive theme__news-category px-2">
          <div class="row clearfix">
            <div
              class="column full text-center iv2-blog-search pt-2"
              style="z-index: 1"
            >
              <h4 class="theme__heading-text size-24">
                {{ $store.state.publicLang.blog.search_category }}
              </h4>
            </div>
            <div class="iv2-blog-category d-none d-xl-block col-12">
              <div class="iv2-blog-category__block d-inline-block">
                <label
                  :class="{ active: isActive == 0 }"
                  class="iv2-blog-category__button theme__category--button"
                >
                  <input
                    @click="clickChange(0)"
                    type="radio"
                    value=""
                    name="category"
                    class="iv2-blog-category__button-input"
                    autocomplete="off"
                  />{{ $store.state.publicLang.news.all_category }}
                </label>
                <label
                  v-for="category in categories"
                  :key="category.id"
                  :class="{ active: isActive == category.id }"
                  class="iv2-blog-category__button theme__category--button"
                >
                  <input
                    type="radio"
                    @click="clickChange(category.id)"
                    :value="category.id"
                    :id="'sort_' + category.id"
                    name="category"
                    class="iv2-blog-category__button-input"
                    autocomplete="off"
                  />{{ category.name }} ({{ category.news_count }})
                </label>
              </div>
            </div>
            <div
              class="iv2-blog-category__mobile col-12 d-flex d-xl-none align-items-center justify-content-center"
            >
              <select
                @change="selectChange($event)"
                class="iv2-blog-category__select theme__news-category__mobile--text w-100 m-3"
                name="archive"
              >
                <option :selected="isActive == 0" value="0">
                  {{ $store.state.publicLang.news.all_category }}
                </option>
                <option
                  v-for="category in categories"
                  :key="category.id"
                  :value="category.id"
                  :selected="isActive == category.id"
                >
                  {{ category.name }} ({{ category.news_count }})
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row clearfix">
      <div class="column full">
        <div class="spacer height-80"></div>
      </div>
      <div
        v-if="
          (isActive === 0 && items.title) || (isActive === 0 && items.subtitle)
        "
        class="column full text-center"
        style="z-index: 1"
      >
        <p v-if="items.subtitle" class="size-14">
          {{ items.subtitle }}
        </p>
        <h3 v-if="items.title" class="theme__heading-text">
          {{ items.title }}
        </h3>
      </div>
      <div
        v-if="isActive > 0"
        class="column full text-center"
        style="z-index: 1"
      >
        <h3 class="theme__heading-text">
          {{ targetCategory() }}
        </h3>
      </div>
      <div v-if="explanation" class="column full">
        <div class="spacer height-60"></div>
        <p class="text-pre m-0 text-center">{{ explanation }}</p>
      </div>
    </div>
    <div v-if="blog.length == 0" class="iv2-blog__null row clearfix">
      <div class="column full">
        <div style="padding: 2rem 0">
          <h4 class="size-24 text-center font-weight-bold">
            {{ $store.state.publicLang.news.null_title }}
          </h4>
          <p class="text-center">
            {{ $store.state.publicLang.news.null_summary }}
          </p>
        </div>
        <div class="spacer height-60"></div>
      </div>
    </div>
    <div v-else class="row clearfix">
      <!-- 記事一覧 -->
      <div class="column full">
        <div class="spacer height-60"></div>
      </div>
      <div
        v-for="item in blog"
        :key="item.id"
        class="iv2-blog-item mb-3 mb-md-4 col-6 col-xl-4"
      >
        <NewsCard :content="item" />
      </div>
      <div class="column full">
        <div class="spacer height-60"></div>
      </div>
    </div>
  </div>
</template>

<script>
import NewsCard from "@/components/items/BaseNewsCard.vue";

export default {
  components: {
    NewsCard,
  },

  props: {
    blog: Array,
    items: Object,
    categories: Array,
    archives: Array,
    explanation: String,
  },

  data() {
    return {
      isActive: 0,
    };
  },

  watch: {
    $route(to, from) {
      if (to.query !== from.query) {
        this.getCategoryId();
      }
    },
  },

  mounted() {
    this.gridHeight();
    this.getCategoryId();
  },

  updated() {
    this.gridHeight();
  },

  methods: {
    getCategoryId() {
      if (this.$route.query.category_id) {
        this.isActive = this.$route.query.category_id;
      } else {
        this.isActive = 0;
      }
    },

    targetCategory() {
      var target = this.categories.filter((e) => e.id == this.isActive);
      return target[0].name;
    },

    gridHeight() {
      let title = document.getElementsByClassName("iv2-news-card__title");
      let titleMaxHeight = 0;
      let titleArray = new Array();

      Array.prototype.forEach.call(title, (el) => {
        el.style.height = "";
        titleArray.push(el.clientHeight);
      });

      titleMaxHeight = Math.max.apply(null, titleArray);

      Array.prototype.forEach.call(title, (el) => {
        el.style.height = titleMaxHeight + "px";
      });
    },

    clickChange(id) {
      if (this.isActive != id) {
        this.$emit("click-change", id);
      }
    },

    selectChange(event) {
      this.$emit("select-change", event);
    },

    selectArchive(event) {
      this.$emit("select-archive", event);
    },
  },
};
</script>

<style lang="scss">
.iv2-blog__null {
  padding: 8rem 0;
}

.iv2-blog-archive {
  border-radius: 15px;
}

/* PC */
@media (min-width: 1200px) {
  .iv2-blog-archive {
    .iv2-blog-category {
      padding: 0 50px;
      text-align: center;

      .iv2-blog-category__block {
        padding-bottom: 20px;
        .iv2-blog-category__button {
          display: inline-block;
          border-radius: 20px;
          margin: 10px 15px;
          padding: 5px 20px;
          font-size: 14px;
          -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
          -webkit-transition: 0.2s;
          transition: 0.2s;
          cursor: pointer;

          .iv2-blog-category__button-input {
            display: none;
            :checked {
              .iv2-blog-category__button {
                border-bottom: 3px solid;
              }
            }
          }
        }
      }
    }
  }
}

/* TABLET or SP */
@media (max-width: 1199px) {
  .iv2-blog-archive {
    padding: 10px;

    .iv2-blog-category__select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border-radius: 0;
      border: 1px solid #000;
      padding: 0 10px;
      background-color: #fff;
    }

    .iv2-blog-category__mobile:before {
      content: "";
      right: 45px;
      display: block;
      position: absolute;
      z-index: 3;
      width: 7px;
      height: 7px;
      border-top: 2px solid #000;
      border-right: 2px solid #000;
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
    }
  }
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-blog-item {
    padding-right: 5px;
    padding-left: 5px;
  }
}
</style>
