<template>
  <transition name="scroll" appear>
    <div
      v-if="scrollY < 100"
      class="iv2-home__scroll-bar theme__scroll position-absolute d-none d-xl-block text-center"
      @click="scroll"
    >
      <span class="iv2-home__scroll-bar-text font-speedee">{{
        $store.state.themeLang.general.scroll
      }}</span>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      scrollY: 0,
    };
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  methods: {
    handleScroll() {
      this.scrollY = window.scrollY;
    },

    scroll() {
      var section = document.getElementsByClassName("iv2-global");
      var header = section[0].clientHeight;
      var height = window.innerHeight - header;
      window.scrollTo({
        top: height,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss">
.iv2-home__scroll-bar {
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  bottom: 30px;
  padding-bottom: 20px;
  font-size: 12px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 400;
  letter-spacing: 5px;
  height: 80px;
  cursor: pointer;
  z-index: 111;
  mix-blend-mode: exclusion;

  &::before {
    content: "";
    width: 8px;
    height: 8px;
    border-right: 1px solid;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateY(calc(-50% + 3px)) translateX(-50%) rotate(45deg);
    -webkit-transform: translateY(calc(-50% + 3px)) translateX(-50%)
      rotate(45deg);
  }

  &::after {
    content: "";
    height: 48px;
    width: 1px;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
  }

  &.scroll-enter-active,
  &.scroll-leave-active {
    transition: all 1s ease;
  }

  &.scroll-enter,
  &.scroll-leave-to {
    transform: translateY(-50px) translateX(-50%) !important;
    opacity: 0;
  }
}
</style>
