<template>
  <div
    @mouseleave="activeColor = false"
    class="iv2__color-changer d-none d-xl-flex align-items-center"
  >
    <div
      @mouseover="activeColor = true"
      class="iv2__color-changer--arrow rounded-left"
    >
      <i class="fas fa-caret-left size-38 my-2"></i>
    </div>
    <ul
      class="iv2__color-changer--block list-unstyled m-0"
      :class="{ active: activeColor }"
    >
      <li
        v-for="colors in $store.state.themeLang.color"
        :key="colors.id"
        @click="changer(colors.code)"
        class="iv2__color-changer--list"
      >
        <span
          v-tooltip.left="{
            content: colors.name,
            delay: { show: 100, hide: 100 },
          }"
          :class="'theme__scheme-' + colors.code"
          class="iv2__color-changer--tile"
        ></span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeColor: false,
    };
  },

  methods: {
    changer(val) {
      document.body.className = "";
      document.body.className = "theme-e__" + val;
    },
  },
};
</script>

<style lang="scss">
.tooltip {
  display: block !important;
  z-index: 111111;
}
.tooltip .tooltip-inner {
  background: rgba(255, 255, 255, 0.9);
  color: #000;
  border-radius: 5px;
  padding: 5px 10px 4px;
  margin-right: 10px;
}
.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: rgba(255, 255, 255, 0.9);
  z-index: 1;
}
.tooltip[x-placement^="left"] {
  margin-right: 5px;
}
.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: 5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}
.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}
.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}

.iv2__color-changer {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 111111;

  .iv2__color-changer--arrow {
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    width: 20px;
    padding-left: 3px;
  }

  .iv2__color-changer--block {
    background-color: rgba(0, 0, 0, 0.8);
    width: 0px;
    height: 100%;
    transition: 0.3s;

    &.active {
      width: 60px;
    }

    .iv2__color-changer--list {
      background-color: #fff;
      margin: 13px;
      padding: 1px;

      .iv2__color-changer--tile {
        display: block;
        margin: 1px;
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
    }
  }
}
</style>
