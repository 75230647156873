<template>
  <div v-if="active" class="iv2__host-name d-none d-xl-block position-absolute">
    <p class="size-12 m-0 text-center font-speedee">{{ hostname }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hostname: "",
      active: true,
    };
  },

  watch: {
    $route(to, from) {
      if (to.path !== from.path && this.$route.path !== "/") {
        this.active = false;
      } else {
        this.active = true;
      }
    },
  },

  created() {
    this.hostname = location.host;

    if (this.$route.path !== "/") {
      this.active = false;
    }
  },
};
</script>

<style lang="scss">
.iv2__host-name {
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  letter-spacing: 7px;
  padding: 110px 0 50px;
  height: 100vh;
  font-family: "Times New Roman", "Hiragino Mincho ProN", "Noto serif JP", serif;
}
</style>
