<template>
  <header
    :class="{ scroll: scrollY > 200 }"
    class="iv2-global theme__global-bg position-fixed"
  >
    <div class="iv2-global__items">
      <h1 class="iv2-global__logo">
        <router-link
          v-if="information.sp_logo_image_header"
          :to="'/' + lang + query"
          class="iv2-global__link"
        >
          <picture class="d-none d-md-inline">
            <source
              type="image/webp"
              :srcset="
                information.logo_image_header +
                $store.state.publicLang.general.image_webp
              "
            />
            <img
              :src="information.logo_image_header"
              class="iv2-global__logo-img"
              :alt="organization.name"
            />
          </picture>
          <picture class="d-md-none">
            <source
              type="image/webp"
              :srcset="
                information.sp_logo_image_header +
                $store.state.publicLang.general.image_webp
              "
            />
            <img
              :src="information.sp_logo_image_header"
              class="iv2-global__logo-img"
              :alt="organization.name"
            />
          </picture>
        </router-link>
        <router-link v-else :to="'/' + lang + query" class="iv2-global__link">
          <picture>
            <source
              type="image/webp"
              :srcset="
                information.logo_image_header +
                $store.state.publicLang.general.image_webp
              "
            />
            <img
              :src="information.logo_image_header"
              class="iv2-global__logo-img"
              :alt="organization.name"
            />
          </picture>
        </router-link>
      </h1>
      <div
        class="mr-xl-2 d-none d-md-block d-xl-none"
        style="
          font-family: 'Times New Roman', 'Hiragino Mincho ProN',
            'Noto serif JP', serif;
          width: 40%;
        "
      >
        <div
          v-if="organization.tel_number"
          class="iv2-nav__header-tel d-inline-flex"
        >
          <span class="mt-auto mr-2 font-speedee">{{
            $store.state.themeLang.general.tel
          }}</span>
          <p
            class="iv2-nav__header-tel--text theme__nav-tel font-speedee mb-0 size-38"
            style="line-height: 1.5"
          >
            {{ organization.tel_number }}
          </p>
        </div>
        <p
          v-if="organization.opening_hours"
          v-html="organization.opening_hours"
          class="iv2-nav__header-time size-12 mt-1"
          style="line-height: 1.3"
        ></p>
      </div>
      <div @click="touchHandler" class="iv2-global__menu text-center m-0">
        <div class="iv2-global__btn d-inline-block">
          <div
            :class="{ active: activeMenu }"
            class="iv2-global__btn-icon position-relative bg-transparent p-0"
          >
            <span class="iv2-global__top-drawer theme__global-icon"></span>
            <span class="iv2-global__bottom-drawer theme__global-icon"></span>
          </div>
        </div>
      </div>
    </div>

    <nav
      :class="[{ active: activeMenu }, navColorClass]"
      class="iv2-nav theme__nav-text"
    >
      <div class="iv2-nav__block">
        <div class="iv2-nav__list">
          <div
            v-for="nav in global"
            :key="nav.id"
            class="iv2-nav__item theme__nav-item"
            :class="{ selected: activeNav === nav }"
            @mouseover="activeNav = nav"
            @mouseleave="activeNav = ''"
            @click="closeMenu"
          >
            <router-link
              v-if="nav.link_type == 'internal'"
              :to="nav.link_href + lang + query"
              :target="nav.target_type"
              class="iv2-nav__link theme__nav-link"
              >{{ nav.item_name }}</router-link
            >
            <a
              v-else-if="nav.link_type == 'external'"
              :href="nav.link_href"
              :target="nav.target_type"
              rel="noopener"
              class="iv2-nav__link theme__nav-link"
              >{{ nav.item_name }}</a
            >
            <div
              v-if="nav.children.length > 0"
              :class="[navChildColor, setHeight(nav.children.length)]"
              class="iv2-nav__list"
            >
              <div
                v-for="cNav in nav.children"
                :key="cNav.id"
                class="iv2-nav__item theme__nav-item--inner"
                @click="closeMenu"
              >
                <router-link
                  v-if="cNav.link_type == 'internal'"
                  :to="cNav.link_href + lang + query"
                  :target="cNav.target_type"
                  class="iv2-nav__link theme__nav-link--inner"
                  >{{ cNav.item_name }}</router-link
                >
                <a
                  v-else-if="cNav.link_type == 'external'"
                  :href="cNav.link_href"
                  :target="cNav.target_type"
                  rel="noopener"
                  class="iv2-nav__link theme__nav-link--inner"
                  >{{ cNav.item_name }}</a
                >
              </div>
            </div>
          </div>
        </div>

        <div class="iv2-nav__footer">
          <div class="row m-0 w-100 h-100">
            <div class="iv2-nav__footer-container col-12 d-xl-flex">
              <div class="iv2-nav__footer-item mr-xl-2 col-xl-6 pr-0">
                <p class="iv2-nav__footer-item--orgname m-0 d-xl-none">
                  {{ organization.name }}
                </p>
                <div
                  v-if="organization.tel_number"
                  class="iv2-nav__footer-tel d-inline-flex d-xl-inline-flex d-md-none"
                >
                  <span class="mt-auto mr-1 font-speedee">{{
                    $store.state.themeLang.general.tel
                  }}</span>
                  <p
                    class="iv2-nav__footer-tel--text theme__nav-tel font-speedee mt-1"
                    style="line-height: 1"
                  >
                    {{ organization.tel_number }}
                  </p>
                </div>
                <div
                  v-if="organization.opening_hours"
                  class="iv2-nav__footer-time"
                >
                  <p
                    v-html="organization.opening_hours"
                    class="size-12 m-0"
                    style="line-height: 1.3"
                  ></p>
                </div>
              </div>
              <div class="iv2-nav__footer-item col-xl-6 pr-0 pl-xl-2">
                <p
                  v-if="organization.free_text"
                  v-html="organization.free_text"
                  class="size-12 m-0"
                  style="line-height: 1.3"
                ></p>
                <div
                  class="iv2-nav__footer-adress p-0"
                  style="line-height: 1.3"
                >
                  <span v-if="organization.zip_code" class="size-12 m-0">{{
                    "〒" + organization.zip_code
                  }}</span>
                  <span
                    v-if="organization.prefecture_name"
                    class="size-12 m-0"
                    >{{ organization.prefecture_name }}</span
                  >
                  <span v-if="organization.city_name" class="size-12 m-0">{{
                    organization.city_name
                  }}</span>
                  <span v-if="organization.town_name" class="size-12 m-0">{{
                    organization.town_name
                  }}</span>
                  <span v-if="organization.building_name" class="size-12 m-0">{{
                    organization.building_name
                  }}</span>
                </div>
              </div>
            </div>
            <div class="col-12 d-none d-md-block d-xl-none p-0 mt-2">
              <div
                v-if="
                  sns.facebook &&
                  sns.instagram &&
                  sns.twitter &&
                  sns.line &&
                  rss.outbound
                "
                class="iv2-nav__mobile-sns m-auto d-flex"
              >
                <span
                  v-if="
                    !(
                      sns.is_linked_facebook == 0 &&
                      sns.is_linked_instagram == 0 &&
                      sns.is_linked_twitter == 0 &&
                      sns.is_linked_line == 0
                    )
                  "
                  class="size-12 mr-3 pt-1"
                  style="
                    letter-spacing: 5px;
                    font-family: 'Times New Roman', 'Hiragino Mincho ProN',
                      'Noto serif JP', serif;
                  "
                  >{{ $store.state.themeLang.general.sns }}</span
                >
                <ul class="iv2-nav__mobile-sns-list mb-0 list-unstyled">
                  <li
                    v-if="sns.is_linked_facebook == 1"
                    class="iv2-nav__mobile-sns-item"
                  >
                    <a
                      :href="sns.facebook_url"
                      class="iv2-nav__mobile-sns-link theme__nav-sns-link"
                      target="_blank"
                      rel="noopener"
                    >
                      <i class="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li
                    v-if="sns.is_linked_instagram == 1"
                    class="iv2-nav__mobile-sns-item"
                  >
                    <a
                      :href="sns.instagram_url"
                      class="iv2-nav__mobile-sns-link theme__nav-sns-link"
                      target="_blank"
                      rel="noopener"
                    >
                      <i class="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li
                    v-if="sns.is_linked_twitter == 1"
                    class="iv2-nav__mobile-sns-item"
                  >
                    <a
                      :href="sns.twitter_url"
                      class="iv2-nav__mobile-sns-link theme__nav-sns-link"
                      target="_blank"
                      rel="noopener"
                    >
                      <i class="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li
                    v-if="sns.is_linked_line == 1"
                    class="iv2-nav__mobile-sns-item"
                  >
                    <a
                      :href="sns.line_url"
                      class="iv2-nav__mobile-sns-link theme__nav-sns-link"
                      target="_blank"
                      rel="noopener"
                    >
                      <i class="fab fa-line"></i>
                    </a>
                  </li>
                  <!-- <li
                    v-if="rss.is_linked_blog == 1"
                    class="iv2-nav__mobile-sns-item"
                  >
                    <a
                      :href="rss.url"
                      class="iv2-nav__mobile-sns-link theme__nav-sns-link"
                      target="_blank"
                      rel="noopener"
                    >
                      <i class="fas fa-rss"></i>
                    </a>
                  </li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>

    <!-- フリーページ項目に対応した動的height値指定 -->
    <div is="style">
      @media (min-width: 1200px) {
      {{ setHeight() }}
      }
    </div>
    <!-- フリーページ項目に対応した動的height値指定 -->
  </header>
</template>

<script>
export default {
  props: {
    global: Array,
    sns: Object,
    organization: Object,
    information: Object,
    rss: Object,
    count: Number,
  },

  data() {
    return {
      activeMenu: false,
      activeNav: "",
      navColorClass: "",
      navChildColor: "",
      scrollY: 0,
      query: "",
      lang: "",
      environment: "",
    };
  },

  watch: {
    $route(to, from) {
      if (to.path !== from.path) {
        if (window.innerWidth <= 1199) {
          this.activeMenu = false;
        }
        const el = document.body;
        el.classList.remove("noscroll");
      }
    },
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },

  mounted() {
    if (window.innerWidth <= 1200) {
      this.activeMenu = false;
    } else {
      this.activeMenu = true;
    }

    if (this.$route.query.mode == "preview") {
      this.query = "?mode=preview";
    }
    if (this.$route.query.hl) {
      this.lang = "?hl=" + this.$route.query.hl;
    }
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },

  methods: {
    handleResize() {
      if (window.innerWidth >= 1199) {
        this.navColorClass = "theme__global-bg";
      } else {
        this.navColorClass = "theme__nav-bg";
      }

      if (window.innerWidth >= 1199) {
        this.navChildColor = "theme__nav-bg";
      } else {
        this.navChildColor = "";
      }
    },

    handleScroll() {
      this.scrollY = window.scrollY;
    },

    touchHandler() {
      this.activeMenu = !this.activeMenu;

      if (this.activeMenu == true) {
        const el = document.body;
        el.classList.add("noscroll");
      } else {
        const el = document.body;
        el.classList.remove("noscroll");
      }
    },

    closeMenu() {
      if (window.innerWidth <= 1199) {
        this.activeMenu = false;
        const el = document.body;
        el.classList.remove("noscroll");
      }
    },

    setHeight(cnt = 0) {
      return cnt > 10 ? "iv2-global__nav-high" : "";
    },
  },
};
</script>

<style lang="scss">
.iv2-global {
  transition: 0.5s;

  &.scroll {
    -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }

  .iv2-global__items {
    .iv2-global__logo {
      margin: auto;
      position: relative;
      height: 100%;

      .iv2-global__logo-img {
        padding: 10px 0;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        max-height: 100%;
        max-width: 100%;
      }
    }

    .iv2-global__menu {
      position: relative;

      .iv2-global__btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateY(-50%) translateX(-50%);
        -webkit-transform: translateY(-50%) translateX(-50%);

        .iv2-global__btn-icon {
          width: 38px;
          height: 17px;
          cursor: pointer;

          .iv2-global__top-drawer,
          .iv2-global__bottom-drawer {
            display: block;
            position: absolute;
            width: 100%;
            height: 2px;
            margin: auto;
            right: 0;
            left: 0;
            transition: 0.3s;
          }

          .iv2-global__top-drawer {
            top: 2px;
          }

          .iv2-global__bottom-drawer {
            bottom: 2px;
          }

          &.active {
            .iv2-global__top-drawer {
              top: 0;
              bottom: 0;
              transform: rotate(-45deg);
            }

            .iv2-global__bottom-drawer {
              top: 0;
              bottom: 0;
              transform: rotate(45deg);
            }
          }

          &:focus {
            outline: 0;
          }
        }
      }
    }
  }

  .iv2-nav {
    width: 100%;
    visibility: hidden;
    display: none;

    &.active {
      visibility: visible;
      display: block;
    }

    .iv2-nav__block {
      > .iv2-nav__list {
        display: inline-block;
        vertical-align: top;

        .iv2-nav__item {
          font-size: 13px;
          font-family: Arial, Helvetica, sans-serif;
          letter-spacing: 1.2px;
          font-weight: bold;

          .iv2-nav__link {
            &:hover {
              text-decoration: none;
            }
          }

          > .iv2-nav__list {
            padding-left: 20px;
            padding-left: 0;
          }
        }
      }
    }

    .iv2-nav__footer {
      margin-left: 0;
      margin-right: 0;

      .iv2-nav__footer-tel {
        width: 100%;
        font-family: "Times New Roman", "Hiragino Mincho ProN", "Noto serif JP",
          serif;
      }

      .iv2-nav__footer-adress {
        text-align: left;
      }
    }
  }
}

/* PC */
@media (min-width: 1200px) {
  @keyframes fade-move {
    0% {
      transform-origin: 0% 0;
      transform: translate3d(0%, 0%, 0px) scale(0, 1);
    }
    100% {
      transform-origin: 0% 0;
      transform: translate3d(0%, 0%, 0px) scale(1, 1);
    }
  }
  .iv2-global {
    padding: 0 50px;
    display: flex;

    .iv2-global__items {
      margin-right: 20px;
      width: 250px;
      .iv2-global__logo {
        max-width: 100%;
      }
      .iv2-global__menu {
        display: none;
      }
    }

    .iv2-nav {
      height: 100%;

      .iv2-nav__block {
        height: 100%;
        display: flex;
        justify-content: space-between;

        > .iv2-nav__list {
          width: 55%;
          display: flex;
          margin: 0;

          .iv2-nav__item {
            margin: 0 auto;
            padding-top: 42px;
            position: relative;

            > .iv2-nav__list.iv2-global__nav-high {
              height: 80vh;
              overflow-y: scroll;
              overflow-x: hidden;
              -ms-overflow-style: none;
              scrollbar-width: none;
            }

            > .iv2-nav__list.iv2-global__nav-high::-webkit-scrollbar {
              display: none;
            }

            > .iv2-nav__list.iv2-global__nav-high {
              height: 80vh;
              overflow-y: scroll;
              overflow-x: hidden;
            }

            > .iv2-nav__list {
              width: 300px;
              height: auto;
              padding: 15px;
              border-radius: 5px;
              position: absolute;
              top: 130px;
              visibility: hidden;
              transition: 0.5s;
              opacity: 0;
              -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
              box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);

              .iv2-nav__item {
                padding-top: 0;
                margin: 10px;

                &:last-child {
                  margin-bottom: 20px;
                }

                .iv2-nav__link {
                  padding-bottom: 10px;
                  display: block;
                }
              }
            }

            &.selected {
              height: 130px;

              > .iv2-nav__list {
                visibility: visible;
                opacity: 1;
              }

              > .iv2-nav__link {
                display: block;
                position: relative;
                &::after {
                  content: "";
                  width: 100%;
                  height: 1px;
                  display: block;
                  position: absolute;
                  bottom: -5px;
                  animation: fade-move 0.3s cubic-bezier(0, 0.3, 0.25, 1);
                }
              }
            }
          }
        }
      }

      .iv2-nav__footer {
        font-family: "Times New Roman", "Hiragino Mincho ProN", "Noto serif JP",
          serif;
        width: 45%;

        .iv2-nav__footer-container {
          padding-left: 0px;
          padding-right: 0px;

          .iv2-nav__footer-item {
            margin: auto;
          }

          .iv2-nav__footer-tel {
            font-size: 16px;

            .iv2-nav__footer-tel--text {
              font-size: 30px;
              margin-top: 1.5rem;
              margin-bottom: 0.5rem;
            }
          }

          .iv2-nav__footer-adress {
            text-align: left;
          }
        }
      }
    }
  }
}

/* TABLET or SP */
@media (max-width: 1199px) {
  @keyframes tooltipShow {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }

    to {
      opacity: 1;
    }
  }
  .iv2-global {
    .iv2-global__items {
      .iv2-global__logo {
        display: inline-block;

        .iv2-global__link {
          margin-bottom: 0;
          height: 100%;
          display: inline-block;
        }
      }
      .iv2-global__menu {
        height: 100%;
        float: right;
        padding: 10px;

        .iv2-global__btn {
          line-height: 1;
        }
      }
    }
    .iv2-nav {
      position: absolute;
      left: 0;
      overflow-y: auto;
      overflow-x: hidden;
      animation: tooltipShow 0.5s linear 0s;

      .iv2-nav__block {
        padding: 30px 20px 20px;

        .iv2-nav__list {
          display: block;
          margin: auto;
          float: none;

          .iv2-nav__item {
            position: relative;
            display: block;
            font-size: 1em;
            margin: 0;

            .iv2-nav__link {
              display: block;
              padding: 15px 0;
            }

            > .iv2-nav__list {
              padding: 0;

              .iv2-nav__item {
                padding-left: 30px;
              }
            }
          }
        }
      }

      .iv2-nav__footer {
        padding: 3rem 0;

        .iv2-nav__footer-container {
          padding: 0;

          .iv2-nav__footer-item {
            padding: 0;
          }
        }
        .iv2-nav__footer-tel {
          font-size: 14px;

          .iv2-nav__footer-tel--text {
            font-size: 14px;
          }
        }
      }

      .iv2-nav__mobile-sns-list {
        .iv2-nav__mobile-sns-item {
          font-size: 14px;
          display: inline-block;
          margin-right: 20px;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

/* TABLET Only */
@media (min-width: 768px) and (max-width: 1199px) {
  .iv2-global {
    .iv2-global__items {
      padding: 0 0 0 50px;
      display: flex;

      .iv2-global__logo {
        max-width: 300px;
        width: 100%;
        margin: 0;
        margin-right: auto;
      }

      .iv2-global__menu {
        width: 110px;
      }

      .iv2-nav__header-tel {
        height: 60%;
        // .iv2-nav__header-tel--text {
        //   font-family: "Times New Roman", "Hiragino Mincho ProN",
        //     "Noto serif JP", serif;
        // }
      }
    }

    .iv2-nav {
      top: 110px;
      height: calc(100vh - 110px);

      .iv2-nav__block {
        padding: 30px 50px 20px;

        .iv2-nav__list {
          border-top: 1px solid;
          position: relative;

          .iv2-nav__item {
            .iv2-nav__link {
              padding: 30px;
              border-bottom: 1px solid;
            }

            > .iv2-nav__list {
              border-top: 0;

              &::before {
                content: none;
              }

              .iv2-nav__item {
                border: 0;
                padding-bottom: 0;
                border-bottom: 1px solid;
                width: 100%;
                position: relative;

                &::before {
                  content: "";
                  width: 15px;
                  border-top: 1px solid;
                  position: absolute;
                  top: 50%;
                  transform: translateY(-50%);
                }

                .iv2-nav__link {
                  border: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

/* SP Only */
@media (max-width: 767px) {
  .iv2-global {
    .iv2-global__items {
      padding: 5px 0px 5px 15px;

      .iv2-global__logo {
        width: 50%;

        .iv2-global__logo-img {
          padding: 0;
          max-width: 100%;
          max-height: 40px;
        }
      }

      .iv2-global__menu {
        padding: 10px;
        width: 50px;

        .iv2-global__btn {
          .iv2-global__btn-icon {
            width: 17px;
            height: 11px;
          }
        }
      }
    }

    .iv2-nav {
      top: 50px;
      height: calc(100vh - 50px);
      padding-bottom: 10rem;
      padding-bottom: calc(10rem + env(safe-area-inset-bottom));

      .iv2-nav__block {
        .iv2-nav__list {
          padding: 0 21px;

          .iv2-nav__item {
            .iv2-nav__link {
              padding: 5px;
            }

            > .iv2-nav__list {
              .iv2-nav__item {
                margin-left: 10px;
              }
            }
          }
        }
      }
      .iv2-nav__footer {
        padding: 3rem 1.5rem;

        .iv2-nav__footer-item {
          .iv2-nav__footer-time,
          .iv2-nav__footer-adress {
            margin: 0.5rem 0;
          }

          .iv2-nav__footer-item--orgname {
            font-size: 1rem;
          }

          .iv2-nav__footer-tel--text {
            font-size: 1.5rem;
            margin-top: 0;
            margin-bottom: 5px;
          }
        }
      }
    }
  }
}
</style>
