<template>
  <aside class="iv2-extra-sns position-fixed text-center d-xl-block">
    <div class="iv2-extra-sns-nav theme__extra-sns-bg position-absolute pt-2">
      <span
        v-if="
          !(
            sns.is_linked_facebook == 0 &&
            sns.is_linked_instagram == 0 &&
            sns.is_linked_twitter == 0 &&
            sns.is_linked_line == 0
          )
        "
        class="iv2-extra-sns-nav-text theme__extra-sns-text d-block text-nowrap font-speedee"
        >{{ $store.state.themeLang.general.sns }}</span
      >
      <ul class="iv2-extra-sns-nav-list list-unstyled">
        <li v-if="sns.is_linked_facebook == 1" class="iv2-extra-sns-nav-item">
          <a
            :href="sns.facebook_url"
            class="iv2-extra-sns-nav-link theme__extra-sns-link"
            target="_blank"
            rel="noopener"
          >
            <i class="iv2-extra-sns-nav-item-icon fab fa-facebook-f"></i>
          </a>
        </li>
        <li v-if="sns.is_linked_instagram == 1" class="iv2-extra-sns-nav-item">
          <a
            :href="sns.instagram_url"
            class="iv2-extra-sns-nav-link theme__extra-sns-link"
            target="_blank"
            rel="noopener"
          >
            <i class="iv2-extra-sns-nav-item-icon fab fa-instagram"></i>
          </a>
        </li>
        <li v-if="sns.is_linked_twitter == 1" class="iv2-extra-sns-nav-item">
          <a
            :href="sns.twitter_url"
            class="iv2-extra-sns-nav-link theme__extra-sns-link"
            target="_blank"
            rel="noopener"
          >
            <i class="iv2-extra-sns-nav-item-icon fab fa-twitter"></i>
          </a>
        </li>
        <li v-if="sns.is_linked_line == 1" class="iv2-extra-sns-nav-item">
          <a
            :href="sns.line_url"
            class="iv2-extra-sns-nav-link theme__extra-sns-link"
            target="_blank"
            rel="noopener"
          >
            <i class="iv2-extra-sns-nav-item-icon fab fa-line"></i>
          </a>
        </li>
        <!-- <li
          v-if="rss.is_linked_blog == 1"
          class="iv2-extra-sns-nav-item"
        >
          <a
            :href="rss.url"
            class="iv2-extra-sns-nav-link theme__extra-sns-link"
            target="_blank"
            rel="noopener"
          >
            <i class="iv2-extra-sns-nav-item-icon fas fa-rss"></i>
          </a>
        </li> -->
      </ul>
    </div>
  </aside>
</template>

<script>
export default {
  props: {
    sns: Object,
    rss: Object,
  },
};
</script>

<style lang="scss">
.iv2-extra-sns {
  mix-blend-mode: exclusion;

  .iv2-extra-sns-nav {
    bottom: 0;
    right: 0;
    padding-bottom: 40px;
    width: 100%;

    .iv2-extra-sns-nav-text {
      font-size: 10px;
      font-weight: 600;
      font-family: "Times New Roman", "Hiragino Mincho ProN", "Noto serif JP",
        serif;
      letter-spacing: 5px;
      -webkit-transform: rotate(90deg);
      transform: rotate(90deg);
    }

    .iv2-extra-sns-nav-list {
      margin-top: 80px;
      margin-bottom: 0;

      .iv2-extra-sns-nav-link {
        margin: 0 10px;
      }

      .iv2-extra-sns-nav-item {
        .iv2-extra-sns-nav-item-icon {
          font-size: 1rem;
        }
      }
    }
  }
}
</style>
